<template>
    <div id="personnelIOStatistics" v-loading="loading" :element-loading-text="$t('carThroughrecords.a0')">
        <div class="header">
      <my-headertitle>{{ $t("personnelIOStatistics.a1") }}</my-headertitle>
      <a-form-model ref="personnelForm" :model="form" :rules="rules">
        <div class="form_row">
                    
            <div class="form_col">
                <a-form-model-item :label="$t('personnelIOStatistics.a2')">
                    <a-radio-group v-model="form.reportType" button-style="solid" @change="rptChange">
                        <a-radio-button  v-for="(itme, index) in reportTypes"
                                :key="index"
                                :value="itme.no"> {{ itme.text }}</a-radio-button>
                    </a-radio-group>
                </a-form-model-item>
            </div>
            
          <div class="form_col">
            <a-form-model-item :label="$t('personnelIOStatistics.a3')">
              <a-input
                v-model="form.devisonName"
                :placeholder="$t('personnelIOStatistics.a4')"
              />
            </a-form-model-item>
          </div>
          <div class="form_col">
            <a-form-model-item :label="$t('personnelIOStatistics.a5')">
              <a-input
                v-model="form.nationalityName"
                :placeholder="$t('personnelIOStatistics.a6')"
              />
            </a-form-model-item>
          </div>
          <div class="form_col">
            <a-form-model-item :label="$t('personnelIOStatistics.a7')">
              <a-input
                v-model="form.personName"
                :placeholder="$t('personnelIOStatistics.a8')"
              />
            </a-form-model-item>
          </div>
          </div>
          <div class="form_row">
          <div class="form_col">
            <a-form-model-item :label="$t('personnelIOStatistics.a9')" prop="date1">
              <a-date-picker
                v-model="form.dateFrom"
                :disabled-date="disabledStartDate"
                show-time
                :allowClear="false"
                format="YYYY-MM-DD HH:mm:ss"
                @change="handleCalendarChange"
              />
            </a-form-model-item>
          </div>
          <div class="form_col">
            <a-form-model-item :label="$t('personnelIOStatistics.a10')" prop="date2">
              <a-date-picker
                v-model="form.dateTo"
                :disabled-date="disabledEndDate"
                show-time
                :allowClear="false"
                format="YYYY-MM-DD HH:mm:ss"
              />
            </a-form-model-item>
          </div>
           
            <div class="form_col">
                <a-form-model-item :label="$t('personnelIOStatistics.a11')">
                  <a-checkbox-group
                    v-model="form.groupbyList"
                    name="checkboxgroup"
                    :options="groupbys"
                  />
                </a-form-model-item>
            </div>

            <div class="form_col">
              <a-form-model-item>
                <a-button type="primary" @click="inquire">{{ $t("personnelIOStatistics.a12") }}</a-button>
                &nbsp;
                &nbsp;
                <a-button type="primary" @click="down" style="margin-left:5px;">{{ $t("personnelIOStatistics.a15") }}</a-button>
                <!--  -->
              </a-form-model-item>
            </div>
        </div>
      </a-form-model>
    </div>
    <div class="main" ref="main">
        <template>
        <a-tabs type="card">
          <a-tab-pane key="1" :tab="$t('personnelIOStatistics.a13')">
            <a-table
              :columns="columns1"
              :dataSource="data1"
              :scroll="size"
              :pagination="pagination"
              size="small"
            >
              
            </a-table>
          </a-tab-pane>
          <a-tab-pane key="2" :tab="$t('personnelIOStatistics.a14')">
             <a-table
                :columns="columns2"
                :dataSource="data2"
                :pagination="false"
                :scroll="size2"
                size="small"
              >
              </a-table>
          </a-tab-pane>
         
        </a-tabs>
      </template>

       

    </div>
    </div>
</template>
<script>
    import headerTitle from "../../components/Title/headerTitle";
    import { getPersonnelStatisticsList, downPersonnelStatisticsList} from "../../api/passageway";
    import moment from "moment";
    import { downloadUrl } from "../../utils/utils";
    export default {
        name: "personnelIOStatistics",
        data() {
            return {
                loading:false,
                initData:undefined,
                reportTypes:[{no:1,text:'每日统计'},{no:2,text:'每周统计'},{no:3,text:'每月统计'}],
                groupbys:null,
                form: {
                    reportType:'1',
                    devisonName: "",
                    nationalityName:"",
                    personName: "",
                    dateFrom: moment().startOf("day"),
                    dateTo: moment().endOf("day"),
                    groupbyList:[],
                },
                rules: {
                    dateFrom: [
                      {
                          required: true,
                          message: this.$t("personnelIOStatistics.a9"),
                          trigger: "change",
                      },
                    ],
                    dateTo: [
                      {
                          required: true,
                          message: this.$t("personnelIOStatistics.a10"),
                          trigger: "change",
                      },
                    ],
                },
                pagination: {
                    total: 0, //数据总数
                    pageSize: 30, //每页中显示10条数据
                    showTotal: (total) => ` ${total} ${this.$t("carThroughrecords.a16")}`, //分页中显示总的数据
                    showQuickJumper: true, //是否可以快速跳转至某页
                    defaultCurrent: 1, //默认当前页面数
                    hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
                    onShowSizeChange: (current, pageSize) => {
                    this.pagination.defaultCurrent = 1;
                    this.pagination.pageSize = pageSize;
                    },
                    // 改变每页数量时更新显示
                    onChange: (current, size) => {
                    this.pagination.defaultCurrent = current;
                    this.pagination.pageSize = size;
                    },
                },
                columns1: [],
                columns2: [],
                data1:[],
                data2:[],
                size: { x: 1400, y: 280 },
                size2: { x: false, y: 280 },
            }
        },
        mounted(){
            this.init();
            this.tableSize();
            window.addEventListener("resize", this.tableSize);
        },
        beforeDestroy() {
            window.removeEventListener("resize", this.tableSize);
        },
        methods:{
            //加载页面初始化数据
            init(){
                let params = {
                    action:0,
                    clientId: this.$store.getters.clientId,
                    sitegrId: this.$store.getters.sitegrId,
                    siteId: this.$route.query.id,
                    tenantId: 0,
                };
                console.log(params);
                this.loading = true;
                getPersonnelStatisticsList(params)
                .then((res) => {
                    console.log('GetPersonnelStatisticsList RESULT',res);
                    this.loading = false;
                    let { data } = res;
                    this.initData = data;
                    this.reportTypes = res.data.reportTypeList;
                    let mgroupbys = new Array();
                    for (let index = 0; index < res.data.groupByList.length; index++) {
                       const element = res.data.groupByList[index];
                       mgroupbys.push({value:element.no,label:element.text});
                    }
                    this.groupbys = [...mgroupbys];
                    console.log('this.groupbys',this.groupbys);
                    this.columns1 = res.data.recordColumns;
                    this.columns2 = res.data.columns;
                })
                .catch((err) => {
                    this.loading = false;
                    console.log(err);
                });
            },
            // 条件查询方法
            inquire() {
            
              this.$refs.personnelForm.validate((valid) => {
                  if (valid) {
                    this.loading = true;
                    let data = {
                          action:1,
                          clientId: this.$store.getters.clientId,
                          sitegrId: this.$store.getters.sitegrId,
                          siteId: this.$route.query.id,
                          tenantId: 0,
                          dateFrom: this.form.dateFrom.format('YYYY/MM/DD HH:mm:ss'),
                          dateTo: this.form.dateTo.format('YYYY/MM/DD HH:mm:ss'),
                          devisonName: this.form.devisonName,
                          nationalityName: this.form.nationalityName,
                          personName: this.form.personName,
                          reportType: this.form.reportType,
                          groupbyList:this.form.groupbyList,
                    };
                    getPersonnelStatisticsList(data)
                    .then((res) => {
                        console.log('GetPersonnelStatisticsList RESULT',res);
                        this.loading = false;
                        let { data } = res;
                        this.initData = data;
                        this.columns1 = res.data.recordColumns;
                        this.columns2 = res.data.columns;

                        this.data1 = res.data.recordStatisticsDatas;
                        this.data2 = res.data.statisticsDatas;
                    })
                    .catch((err) => {
                        this.loading = false;
                        console.log(err);
                    });
                  } else {
                    return false;
                  }
                });
            },
            down(){
               this.$refs.personnelForm.validate((valid) => {
                  if (valid) {
                    this.loading = true;
                    let data = {
                          action:1,
                          clientId: this.$store.getters.clientId,
                          sitegrId: this.$store.getters.sitegrId,
                          siteId: this.$route.query.id,
                          tenantId: 0,
                          dateFrom: this.form.dateFrom.format('YYYY/MM/DD HH:mm:ss'),
                          dateTo: this.form.dateTo.format('YYYY/MM/DD HH:mm:ss'),
                          devisonName: this.form.devisonName,
                          nationalityName: this.form.nationalityName,
                          personName: this.form.personName,
                          reportType: this.form.reportType,
                          groupbyList:this.form.groupbyList,
                    };
                    downPersonnelStatisticsList(data)
                    .then((res) => {
                        console.log('DownPersonnelStatisticsList RESULT',res);
                        this.loading = false;
                        let name  = (this.$store.getters.siteName?this.$store.getters.siteName+'-':'');
                        name+=this.$t("personnelIOStatistics.a1");
                        name+="-";
                        name+=this.form.dateFrom.format('YYYY-MM-DD HH:mm:ss');
                        name+="~";
                        name+=this.form.dateTo.format('YYYY-MM-DD HH:mm:ss');
                        name+="-";
                        name+=moment().format('YYYYMMDDHHmmssSSS')+'.zip';
                        console.log("DownloadEnergyCSVData Result>>",name);
                        downloadUrl(res.data,name);
                    })
                    .catch((err) => {
                        this.loading = false;
                        console.log(err);
                    });
                  } else {
                    return false;
                  }
                }); 
            },
             // 表格自适应方法
            tableSize() {
              let height = this.$refs.main.clientHeight;
              let tableHeight = height - 160;
              this.size.y = tableHeight;
              this.size2.y = tableHeight;
            },
            // 初始日期/时间选择变化回调
            handleCalendarChange(value, mode) {
                const _val = JSON.stringify(value);
                const val = JSON.parse(_val);
                // console.log(moment(val).endOf("day"));
                this.form.dateTo = moment(val).endOf("day");
            },
            // 开始日期限制选择范围（未来日期不可选）
            disabledStartDate(startValue) {
                // 获取计算机当前日期
                const nowValue = moment();
                if (!startValue || !nowValue) {
                    return false;
                }
                // 禁用大于计算机日期的日期值
                return startValue.valueOf() > nowValue.valueOf();
            },
            // 终止日期/时间的日期不可选范围
            disabledEndDate(endValue) {
                const startValue = this.form.dateFrom;
                const _value = JSON.stringify(startValue);
                const value = JSON.parse(_value);
                const nowValue = moment().endOf("day");
                if (!endValue || !startValue) {
                    return false;
                }
                return (
                    startValue.valueOf() > endValue.valueOf() ||
                    endValue.valueOf() > nowValue.valueOf() ||
                    moment(value).add(1, "months").valueOf() <= endValue.valueOf()
                );
            },
            rptChange(e){
                console.log('rptChange',e);
                let rpt = e.target.value;
                const nowValue = moment().endOf("day");
                this.form.dateTo = nowValue;
                if('1'===rpt){
                  this.form.dateFrom = moment().startOf("day");
                }else if('2'===rpt){
                  const preDay = moment().subtract(6, 'days');
                  this.form.dateFrom = preDay.startOf("day");
                }else if('3'===rpt){
                  const preDay = moment().subtract(1, 'months').add(1, 'days');
                  this.form.dateFrom = preDay.startOf("day");
                }

                this.data1 = new Array();
                this.data2 = new Array();
            },
        },
         components: {
          "my-headertitle": headerTitle,
        },
    }
</script>
<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
#personnelIOStatistics {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.header {
  height: 240px;
  padding: 0 20px;
  overflow: hidden;
}
.main {
  width: 100%;
  height: calc(100% - 240px);
  padding: 0px 20px;
  overflow: hidden;
}
.form_row {
  display: flex;
  align-items: flex-end;
}
.form_col {
  margin-right: 15px;
  min-width: 1px;
  white-space: nowrap;
}
.fool {
  width: 100px;
  height: 10px;
  
}
.scs{
  width: 792px;
    /* height: 540px; */
    background-color: #000000;
    position: absolute;
    top: -20px;
    text-align: center;
    left: -20px;
    z-index: 9999;
    /* opacity: 0.3; */
    cursor: pointer;
}
</style>
