import request from '@/utils/request'
import fileDownload from '@/utils/fileDownload'

// 人员统计
export function getPersonnelStatisticsList(params) {
    console.log("GetPersonnelStatisticsList",params);
    return request({
        url: "bivale/passageway/personnel/statistics",
        method: 'post',
        data:params,
    });
}

// 人员统计--数据下载
export function downPersonnelStatisticsList(body, params) {
    return fileDownload({
        url: "bivale/passageway/personnel/statistics/download",
        method: 'post',
        responseType: "blob",
        data: body,
        params: params,
    });
}
// 室内人员统计
export function getStayPersonnelStatisticsList(params) {
    console.log("GetCardList",params);
    return request({
        url: "bivale/passageway/stay/personnel/statistics",
        method: 'post',
        data:params,
    });
}

// 人员统计--数据下载
export function downStayPersonnelStatisticsList(body, params) {
    return fileDownload({
        url: "bivale/passageway/stay/personnel/statistics/download",
        method: 'post',
        responseType: "blob",
        data: body,
        params: params,
    });
}



// 出入口通行记录
export function getPassagewayPassRecords(params) {
    return fileDownload({
        url: "bivale/passageway/pass/list",
        method: 'post',
        data: params
    });
}


// 出入口---车牌管理---PersonGr 树结构数据
export function getSitePersonGrTree(params) {
    return request({
        url: "bivale/authentication/carPark/persongr/list",
        method: 'post',
        data: params
    });
}

// 出入口---车牌管理---Person 树结构数据
export function getSitePersonList(params) {
    return request({
        url: "bivale/authentication/carPark/person/list",
        method: 'post',
        data: params
    });
}

// 出入口---车牌管理---车牌信息，通过Person ID获取
export function getSiteCarInfoByPerson(params){
    return request({
        url: "bivale/authentication/carPark/person/car/info",
        method: 'post',
        data: params,
    });
}
// 出入口---车牌管理---保存人员和车牌信息
export function saveSiteCarInfoByPerson(params){
    return request({
        url: "bivale/authentication/carPark/person/car/save",
        method: 'post',
        data: params,
    });
}


// / 出入口---车牌管理---导出车牌信息/下载模板文件
export function downloadPersonCarInfoList(params) {
    return request({
        url: "bivale/authentication/carPark/car/download",
        method: 'post',
        data: params
    });
}

// / 出入口---车牌管理---批量上传车牌信息
export function uploadCarPlateData(params){
    return request({
        url: 'bivale/authentication/carPark/car/upload',
        method: 'post',
        processData: false,
        data:params
    });
}